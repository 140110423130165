<template>
  <section class="products d-block mt-2">
    <img
      v-if="props.catigories[tab].banner"
      class="products_banner"
      style="width: 100%; height: auto"
      :src="props.catigories[tab].banner"
      :alt="getImageName(props.catigories[tab].banner)" :title="getImageName(props.catigories[tab].banner)" 
    />
    <div class="product_ttl_block">
      <h2 v-if="props.title" class="product__title_">{{ props.title }}</h2>
      <p v-if="props.depiction" class="product__depiction">{{
        props.depiction
      }}</p>
    </div>
    <div class="title_product_" style="margin-left: auto; margin-right: auto">
      <v-tabs
        v-if="props.catigories.length > 1 && specialCategoriesValue.length > 0"
        hide-slider
        v-model="tab"
        :show-arrows="false"
        :class="{ 'mb-6': $vuetify.breakpoint.smAndDown }"
      >
        <v-tab
          v-for="item in props.catigories.map(
            (catItem) =>
              specialCategoriesValue.find(
                (specialCatItem) => specialCatItem.title.ru === catItem.catigory
              ) || {}
          )"
          :key="item.id"
          :class="{ 'px-5': $vuetify.breakpoint.mdAndUp }"
          :ripple="false"
          @click="getProductsByCategory(item)"
        >
          {{ item.title.ru }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <div class="pa-0 w-100">
        <transition name="slide-fade" mode="out-in">
          <v-row key="1" class="list_product width-product">
            <v-col
              v-for="product in products"
              :key="product.id"
              cols="6"
              md="4"
              class="pa-3"
            >
              <SpecialProductCard
                :product="product"
                @click.native="
                  () => {
                    $router.push({
                      path: `/special-product/${product.slug}`,
                      params: { id: product.slug }
                    })
                    setSelectedProduct(product)
                  }
                "
                @goToDetails="
                  () => {
                    $router.push({
                      path: `/special-product/${product.slug}`,
                      params: { id: product.slug }
                    })
                    setSelectedProduct(product)
                  }
                "
              />
            </v-col>
          </v-row>
        </transition>
      </div>
    </v-tabs-items>
    <div
      v-if="props.catigories[tab].buttonText"
      style="width: 100%; display: flex; align-items: center; margin-top: 20px"
    >
      <a style="margin: 0 auto" :href="props.catigories[tab].buttonLink"
        ><button class="propduct__button">{{
          props.catigories[tab].buttonText
        }}</button></a
      >
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'
const { mapState, mapActions } = createNamespacedHelpers('storeCart')
const { mapActions: Actions_products } =
  createNamespacedHelpers('products')

const {
  mapState: State_specialCategories,
  mapActions: Actions_specialCategories,
} = createNamespacedHelpers('specialCategories')
import SpecialProductCard from '@/view/content/store/SpecialProductCard.vue'

export default {
  name: 'SpecialProduct',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SpecialProductCard
  },
  data() {
    return {
      tab: 0,
      specialCategoriesValue: [],
      products: []
    }
  },
  computed: {
    ...State_specialCategories([
      'specialCategories',
      'category',
      'defaultCategory',
    ]),
  },
  watch: {
    specialCategories(val) {
      const flatten = (arr, result = []) => {
        for (const item of arr) {
          result.push(item);
          if (Array.isArray(item.children) && item.children.length > 0) {
            flatten(item.children, result);
          }
        }
        return result;
      };

      this.specialCategoriesValue = flatten(val);
    },
  },
  methods: {
    ...mapActions(['setSelectedProduct']),
    ...Actions_products(['specialProductByCatigories']),
    ...Actions_specialCategories(['getAllSpecialCategories']),
    async getProductsByCategory(catigory) {
      this.products = await this.specialProductByCatigories({ id: catigory?.id })
    },
  },
  getImageName,
  async mounted() {
    await this.getAllSpecialCategories()

    const category = this.specialCategoriesValue.find(
      (item) => item.title.ru === this.props.catigories[0].catigory
    )

    this.products = await this.specialProductByCatigories({ id: category.id })
  },
}
</script>

<style>
.products_banner {
  margin-bottom: 64px;
}
.product__title_ {
  font-size: 35px;
  margin-bottom: 13px;
}
.product__depiction {
  font-size: 16px;
  color: #666 !important;
}
.title_product .v-slide-group__content {
  justify-content: center !important;
}
.propduct__button {
  background-color: #c84a4a;
  color: #fff;
  padding: 10px 20px;
  margin: 5px auto;
  font-size: 20px;
}
.product_ttl_block {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  margin: 0 auto;
}
.width-product {
  /* width: 1050px !important; */
  margin: 0 auto !important;
  float: unset !important;
  padding: 0 19px;
}
.title_product_ .v-slide-group__content.v-tabs-bar__content {
  justify-content: center;
}
.title_product_ .v-item-group.theme--light {
  margin-bottom: 16px;
  margin-top: 16px;
}

@media (max-width: 844px) {
  .product_ttl_block {
    width: 100%;
  }
  .title_product_
    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(
      .v-slide-group--has-affixes
    )
    .v-slide-group__prev {
    display: none;
  }
  .title_product_
    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(
      .v-slide-group--has-affixes
    )
    .v-slide-group__prev {
    display: none;
  }

  .width-product {
    width: 100% !important;
  }

  .products .list_product .list_items .p_img {
    height: 156px;
    width: 100%;
    min-height: 156px;
    max-height: 156px;
  }

  .products .list_product .list_items .p_img img {
    object-fit: cover;
  }

  .v-application .pa-3 {
    padding: 5px !important;
  }

  .products .list_product .list_items .p_text {
    padding: 5px 0;
  }

  .list_product div .p_text .price {
    padding: 0;
    height: 20px;
  }
}
</style>
